@import './mixins';
@import './breakpoints';

@font-face {
  font-family: 'Inter Black';
  src: local('Inter Black'), url('/employer/assets/fonts/Inter/Inter-Black.woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Bold';
  src: local('Inter Bold'), url('/employer/assets/fonts/Inter/Inter-Bold.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Inter ExtraBold';
  src: local('Inter ExtraBold'), url('/employer/assets/fonts/Inter/Inter-ExtraBold.ttf');
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'Inter Light';
  src: local('Inter Light'), url('/employer/assets/fonts/Inter/Inter-Light.woff');
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro regular';
  src: local('SourceSansPro regular'), url('/employer/assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'SourceSansPro Bold';
  src: local('SourceSansPro Bold'), url('/employer/assets/fonts/SourceSansPro/SourceSansPro-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro Light';
  src: local('SourceSansPro Light'), url('/employer/assets/fonts/SourceSansPro/SourceSansPro-Light.ttf');
  font-display: swap;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  @include p2;
}

a {
  color: inherit;
  text-decoration: none;
}

.mobile-only {
  display: inline;
  @include tablet-up {
    display: none;
  }
}

.tablet-only {
  display: none;
  @include tablet-up {
    display: inline;
  }
  @include desktop-up {
    display: none;
  }
}

.desktop-only {
  display: none;
  @include desktop-up {
    display: inline;
  }
}
